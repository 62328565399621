import React from "react";

export default function LinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 192 192"
      className="LinkIcon"
      style={{ margin: "0 .3rem", verticalAlign: "bottom" }}
    >
      <g strokeMiterlimit="10" textAnchor="none">
        <path
          d="M130.246 29.608c-8.42.404-16.517 4.079-22.777 10.338L82.915 64.5c3.857-3.877 18.173-1.212 21.647 2.262L119.423 51.9c3.291-3.291 7.41-5.31 11.63-5.492 2.868-.142 6.927.464 10.5 4.038 3.333 3.332 4.04 7.209 4.04 9.854 0 4.422-2.02 8.824-5.493 12.277l-25.846 26.008c-6.502 6.502-16.396 7.027-22.13 1.292-3.272-3.271-8.663-3.291-11.955 0-3.291 3.291-3.291 8.662 0 11.954 5.896 5.896 13.65 8.884 21.646 8.884 8.643 0 17.467-3.533 24.231-10.338l26.008-25.846c6.603-6.583 10.338-15.387 10.338-24.231 0-8.218-3.11-16.033-8.884-21.808-6.18-6.179-14.478-9.288-23.262-8.884zM90.185 71.285c-8.643 0-17.608 3.553-24.393 10.338L39.946 107.47c-6.603 6.583-10.338 15.387-10.338 24.231 0 8.218 3.11 16.033 8.884 21.808 6.18 6.179 14.478 9.288 23.262 8.884 8.42-.404 16.517-4.079 22.777-10.338l24.554-24.554c-3.877 3.877-18.173 1.212-21.647-2.262L72.577 140.1c-3.291 3.291-7.41 5.29-11.63 5.492-2.868.142-6.927-.464-10.5-4.038-3.333-3.332-4.04-7.229-4.04-9.854 0-4.422 2.02-8.824 5.493-12.277l25.846-26.008c6.502-6.502 16.396-7.006 22.13-1.292 3.292 3.291 8.684 3.291 11.955 0 3.291-3.291 3.291-8.662 0-11.954-5.896-5.896-13.67-8.884-21.646-8.884z"
          fill="currentColor"
          stroke="none"
        />
      </g>
    </svg>
  );
}
